import { axios } from '@/axios'

export const getHelpCenter = (params = {}) => {
  return axios.get('/help-center', { params }).then((data) => data)
}
export const getHelp = (id) => {
  return axios.get(`/help-center/${id}`).then(({ data }) => data)
}
export const generateHelpCenter = (params) => {
  return axios.post('/help-center', params).then(({ data }) => data)
}
export const deleteHelpCenterTemplate = (id) => {
  return axios.delete(`/help-center/${id}`).then(({ data }) => data)
}
export const updateHelpCenterTemplate = (id, params = {}) => {
  return axios.put(`/help-center/${id}`, params).then(({ data }) => data)
}
