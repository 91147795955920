export default [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/pages/dashboard/index.vue')
  },
  {
    path: '/my-account',
    name: 'my-account',
    component: () => import('../views/pages/my-account/Edit')
  },
  {
    path: '/contests',
    name: 'contests',
    component: () => import('../views/pages/contests/Index')
  },
  {
    path: '/contests/:id',
    name: 'contest_show',
    component: () => import('../views/pages/contests/Show')
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import('../views/pages/orders/Index')
  },
  {
    path: '/orders/:id',
    name: 'order_show',
    component: () => import('../views/pages/orders/Show')
  },
  {
    path: '/terms-of-use',
    name: 'terms_list',
    component: () => import('../views/pages/terms-of-use/Index')
  },
  {
    path: '/terms-of-use/add',
    name: 'terms_new',
    component: () => import('../views/pages/terms-of-use/Form')
  },
  {
    path: '/terms-of-use/edit/:id',
    name: 'terms_edit',
    component: () => import('../views/pages/terms-of-use/Form')
  },
  {
    path: '/numbers',
    name: 'numbers',
    component: () => import('../views/pages/numbers/Index')
  },
  {
    path: '/customers',
    name: 'customers',
    component: () => import('../views/pages/customers/Index')
  },
  {
    path: '/customers/:id',
    name: 'customer_show',
    component: () => import('../views/pages/customers/Show')
  },
  {
    path: '/sellers',
    name: 'sellers',
    component: () => import('../views/pages/sellers/Index')
  },
  {
    path: '/sellers/add',
    name: 'sellers_add',
    component: () => import('../views/pages/sellers/Add')
  },
  {
    path: '/sellers/:id/edit',
    name: 'sellers_edit',
    component: () => import('../views/pages/sellers/Add')
  },
  {
    path: '/contest-default-quotas',
    name: 'contest_default_quotas_list',
    component: () => import('../views/pages/contest-default-quotas/Index')
  },
  {
    path: '/contest-default-quotas/:id/edit',
    name: 'contest_default_quotas_edit',
    component: () => import('../views/pages/contest-default-quotas/Form')
  },
  {
    path: '/reports/billing',
    name: 'reports_billing',
    component: () => import('../views/pages/reports/billing')
  },
  {
    path: '/auth/login',
    name: 'login',
    component: () => import('../views/pages/auth/login.vue')
  },
  {
    path: '/auth/logout',
    name: 'logout',
    component: () => import('../views/pages/auth/logout.vue')
  },
  {
    path: '/auth/lock-screen',
    name: 'lock-screen',
    component: () => import('../views/pages/auth/lock-screen.vue')
  },
  {
    path: '/notification',
    name: 'notification',
    component: () => import('../views/pages/notification/Index')
  },
  {
    path: '/create',
    name: 'create_notification',
    component: () => import('../views/pages/notification/Form.vue')
  },
  {
    path: '/notifications-templates',
    name: 'notifications_templates',
    component: () => import('../views/pages/notification-template/Index')
  },
  {
    path: '/notifications-templates/add',
    name: 'notifications_templates_add',
    component: () => import('../views/pages/notification-template/Add')
  },
  {
    path: '/notificantion-templates/edit/:id',
    name: 'notification-templates_edit',
    component: () => import('../views/pages/notification-template/Add')
  },
  {
    path: '/help-center',
    name: 'help_center',
    component: () => import('../views/pages/help-center/Index')
  },
  {
    path: '/help-center/create',
    name: 'help_center_create',
    component: () => import('../views/pages/help-center/Form.vue')
  },
  {
    path: '/help-center/edit/:id',
    name: 'help-center_edit',
    component: () => import('../views/pages/help-center/Form.vue')
  }
]
