<script>
import Layout from '@/views/layouts/main'
import { onMounted, ref, watch } from 'vue'
import { getHelpCenter, deleteHelpCenterTemplate, getHelp } from './api'
import AppPagination from '@/components/app-pagination'
import Notify from '@/notify'
import debounce from '@/utils/debounce'

const statusMap = {
  ACTIVE: {
    label: 'Ativo',
    class: 'badge-soft-success'
  },
  INACTIVE: {
    label: 'Inativo',
    class: 'badge-soft-primary'
  }
}

export default {
  setup () {
    const helpcenterList = ref([])
    const parentList = ref({})
    const page = ref(1)
    const pages = ref(0)
    const total = ref(0)
    const filter = ref({
      created_at: {
        from: null,
        to: null
      },
      confirmed_at: {
        from: null,
        to: null
      }
    })

    onMounted(() => {
      fetchHelpCenter()
    })

    const deletedHelpCenter = (id) => {
      deleteHelpCenterTemplate(id).then(() => {
        Notify.create({
          type: 'positive',
          title: 'Central de ajuda excluída com sucesso.'
        })
        fetchHelpCenter()
      }).catch(() => {
        Notify.create({
          type: 'negative',
          title: 'Falha ao excluir Central de ajuda.'
        })
      })
    }

    const dateToISO = (date) => {
      if (date && date.length === 10) {
        return date.split('/').reverse().join('-')
      }
      return null
    }

    const fetchHelpCenter = debounce(() => {
      const params = {
        page: page.value,
        created_at: {
          gte: dateToISO(filter.value.created_at.from),
          lte: dateToISO(filter.value.created_at.to)
        },
        order: {
          created_at: 'desc'
        }
      }
      getHelpCenter(params).then((data) => {
        helpcenterList.value = data.data
        pages.value = data.last_page
        total.value = data.total
        fetchParentNames(data.data)
      }).catch(() => {
        Notify.create({
          type: 'negative',
          title: 'Erro ao buscar Central de ajuda.'
        })
      })
    }, 1000)

    const fetchParentNames = (data) => {
      const parentIds = [...new Set(data.map(item => item.parent_id))].filter(Boolean)
      parentIds.forEach(id => {
        getHelp(id).then(response => {
          parentList.value[id] = response.title
        }).catch(() => {
          parentList.value[id] = 'Desconhecido'
        })
      })
    }

    const statusGet = (status, key) => {
      if (statusMap[status]) {
        return statusMap[status][key] ?? 'N/I'
      }
      return 'NotIdentified'
    }

    const formatValidTo = (validTo) => {
      if (Array.isArray(validTo)) {
        return validTo.join(', ')
      }
      return validTo
    }

    const booleanToText = (bool) => {
      return bool ? 'Sim' : 'Não'
    }

    watch(filter, () => {
      page.value = 1
      fetchHelpCenter()
    }, { deep: true })

    return {
      page,
      pages,
      total,
      helpcenterList,
      fetchHelpCenter,
      filter,
      deletedHelpCenter,
      parentList,
      formatValidTo,
      booleanToText,
      statusGet
    }
  },
  components: {
    AppPagination,
    Layout
  }
}
</script>

<template>
  <Layout :pagetitle="'Central de Ajuda'">
    <div class="row">
      <div class="col-lg-12">
        <div class="col-md-12">
          <div class="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
            <div>
              <ul class="nav nav-pills">
                <div>
                  <router-link
                    :to="{ name: 'help_center_create' }"
                    class="btn btn-primary"
                  >
                    <i class="bx bx-plus me-1" /> Gerar uma Central de Ajuda
                  </router-link>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <div class="card mt-3">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-striped mb-4">
                <thead>
                  <tr>
                    <th scope="col">
                      Afiliado de:
                    </th>
                    <th scope="col">
                      Título
                    </th>
                    <th scope="col">
                      Destaque?
                    </th>
                    <th scope="col">
                      Status
                    </th>
                    <th scope="col">
                      Criado em
                    </th>
                    <th scope="col">
                      Atualizado em
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="helpcenter in helpcenterList"
                    :key="helpcenter.id"
                  >
                    <td>{{ parentList[helpcenter.parent_id] || 'Não Possui Afiliado' }}</td>
                    <td>{{ helpcenter.title }}</td>
                    <td>{{ booleanToText(helpcenter.highlight) }}</td>
                    <td>
                      <span
                        class="badge rounded-pill"
                        :class="statusGet(helpcenter.status, 'class')"
                      >
                        {{ statusGet(helpcenter.status, 'label') }}
                      </span>
                    </td>
                    <td>{{ helpcenter.created_at ? $d(helpcenter.created_at, 'info') : '' }}</td>
                    <td>{{ helpcenter.updated_at ? $d(helpcenter.updated_at, 'info') : '' }}</td>
                    <td
                      class="d-flex justify-content-between"
                      style="width: 100%;"
                    >
                      <router-link
                        :to="{ name: 'help-center_edit', params: { id: helpcenter.id, data: helpcenter } }"
                        class="btn btn-outline-info w-100 me-1"
                      >
                        <i class="bx bx-cog" /> Editar
                      </router-link>
                      <a
                        href="#"
                        data-bs-toggle="tooltip"
                        class="btn btn-outline-danger w-100 ms-1"
                        data-bs-placement="top"
                        @click.prevent="deletedHelpCenter(helpcenter.id)"
                      >
                        <i class="bx bx-trash" /> Excluir
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <app-pagination
                v-model="page"
                class="pagination"
                :pages="pages"
                :range-size="2"
                @update:modelValue="fetchHelpCenter"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
