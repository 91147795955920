<script>
import Layout from '@/views/layouts/main'
import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { generateHelpCenter, updateHelpCenterTemplate, getHelp, getHelpCenter } from './api'
import Notify from '@/notify'

export default {
  setup () {
    const route = useRoute()
    const router = useRouter()
    const helpcenter = ref({
      title: '',
      content: '',
      short_description: '',
      status: 'ACTIVE',
      parent_id: null,
      highlight: false
    })
    const helpcenterList = ref([])

    const getHelpCenterList = () => {
      getHelpCenter().then((data) => {
        helpcenterList.value = data.data
      }).catch(() => {
        Notify.create({
          title: 'Erro ao buscar lista de centrais de ajuda!',
          timer: 1500
        })
      })
    }

    const getHelpCenterDetails = () => {
      getHelp(route.params.id)
        .then((data) => {
          helpcenter.value = data
        })
        .catch(() => {
          Notify.create({
            title: 'Erro ao Buscar Central de Ajuda!',
            timer: 1500
          })
        })
    }
    onMounted(() => {
      if (route.params.id) {
        getHelpCenterDetails()
      }
      getHelpCenterList()
    })

    const submitHelpCenterForm = async () => {
      try {
        if (route.params.id) {
          await updateHelpCenterTemplate(route.params.id, helpcenter.value)
        } else {
          await generateHelpCenter(helpcenter.value)
        }
        Notify.create({
          title: 'Dados salvos com sucesso!',
          timer: 1500
        })
        router.push({ name: 'help_center' })
      } catch (error) {
        Notify.create({
          title: 'Erro ao salvar dados!',
          timer: 1500
        })
      }
    }

    const clearParentId = () => {
      helpcenter.value.parent_id = null
    }

    return {
      route,
      helpcenter,
      helpcenterList,
      submitHelpCenterForm,
      clearParentId
    }
  },
  components: {
    Layout
  }
}
</script>

<template>
  <Layout :pagetitle="`${route.params.id ? 'Editar' : 'Gerar'} Central de Ajuda`">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="p-4 border-top">
            <form
              class="was-validated"
              @submit.prevent="submitHelpCenterForm"
            >
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label
                      for="title"
                      class="form-label"
                    >Título</label>
                    <textarea
                      id="title"
                      v-model="helpcenter.title"
                      class="form-control"
                      placeholder="Título"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label
                      for="content"
                      class="form-label"
                    >Conteúdo</label>
                    <textarea
                      id="content"
                      v-model="helpcenter.content"
                      class="form-control"
                      placeholder="Conteúdo"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label
                      for="description"
                      class="form-label"
                    >Descrição</label>
                    <textarea
                      id="description"
                      v-model="helpcenter.short_description"
                      class="form-control"
                      placeholder="Descrição Curta"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label
                      for="Type"
                      class="form-label"
                    >Status</label>
                    <select
                      v-model="helpcenter.status"
                      class="form-select"
                      required
                    >
                      <option value="ACTIVE">
                        Ativo
                      </option>
                      <option value="INACTIVE">
                        Inativo
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <div class="form-check">
                      <input
                        id="highlight"
                        v-model="helpcenter.highlight"
                        type="checkbox"
                        class="form-check-input"
                      >
                      <label
                        for="highlight"
                        class="form-check-label"
                      >Destaque</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="mb-3 d-flex align-items-center">
                    <div class="w-100">
                      <label
                        for="parent_id"
                        class="form-label"
                      >Afiliado de:</label>
                      <select
                        id="parent_id"
                        v-model="helpcenter.parent_id"
                        class="form-select"
                      >
                        <option
                          v-for="parent in helpcenterList"
                          :key="parent.id"
                          :value="parent.id"
                        >
                          {{ parent.title }}
                        </option>
                      </select>
                    </div>
                    <button
                      type="button"
                      class="btn btn-danger ms-2 mt-3"
                      @click="clearParentId"
                    >
                      <i /> Limpar
                    </button>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-wrap align-items-center justify-content-end">
                <router-link
                  :to="{ name: 'help_center' }"
                  class="btn btn-secondary me-2"
                >
                  <i class="bx bx-left-arrow-circle me-1" /> Voltar
                </router-link>
                <button
                  class="btn btn-primary"
                  type="submit"
                >
                  Gerar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
